.modify-products{
  .table-products{
    margin-top: 20px;
  }
}

.add-panel{
  display: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

#add-category-footer-panel{
  display: none;
}

#add-product-footer-panel{
  display: none;
}

#product-selected-message,#category-selected-message{
  display: none;
}
#saved-icon{
  font-size: 80px;
  color: #8BC954;
  position: absolute;
  top: 50%;
  left: 45%;
  transition: 0.5 all ease-in;
  display: none;
}
#preloader-products{
  position: absolute;
  top: 50%;
  left: 45%;
  display: none;
}

.opacity-on{
  opacity: 0.3;
}