div#ib-lp-group{
  h1.title{
    margin-top: 0px;
    &:first-letter{
      text-transform: uppercase;
    }
  }
  .list-block{
    .list{
      border:1px solid #ddd;
      a{
        padding-left: 10px;
        line-height: 26px;
        padding-bottom: 10px;
        &::first-letter {
          text-transform: capitalize;
        }
      }
    }
  }
}

div.ib-groups-list{
  &.ib-groups-list-in-category{
    display: none;
    margin-top: 20px;
  }
  border: 1px solid #ddd;
  height: 51px;
  .options{
    display: block;
    margin: 0;
    line-height: 50px;
    float: left;
    background-color: #333333;
    color: white;
    margin-right: 20px;
    padding: 0px 20px;
  }
  ul{
    li{
      float: left;
      a{
        float: left;
        line-height: 44px;
        margin-right: 20px;
        text-transform: capitalize;
      }
    }
  }
}