.landing-pages-container{
	list-style: none;
	.product_list{

		.ajax_block_product{
			list-style: none;
		}
	}
	.landing-page-footer-description {
		margin-bottom: 20px;
		border-bottom: 1px solid #d6d4d4;
		padding: 10px;
	}
	.iframe-container {
		text-align: center;
	}
	#ib-related-search{
		display: block;
		margin-top:60px;
	}
	.video-container {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
		iframe, object, embed{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
#ib-landing-attr {
	display: none;
}
