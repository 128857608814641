.description-panel{

	.step{
		margin:20px 0px;
	}
	p{
		font-size:14px;
	}

	h1{
		font-size:21px;
		font-weight:bold;
		border-bottom:1px solid #ddd;
	}
	
	h2{
		font-size:16px;
		font-weight:bold;
	}

	h3{
		&.tab{
			font-size: 18px!important;
		}
	}
	.quote{
		background-color: #ddd;

		padding:5px;
	}
}