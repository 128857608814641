#op-link-building{
    background-color: #EBEBEB;
    border-bottom: 1px solid rgba(0,0,0,0.06);
    display: none;
    padding-top: 5px;
     .container {
    clear: both;
        .row .col-md-6 {
            width: 50%;
            float: left;
            text-align: left;
        }
    }
    div .landing-links{
        text-align: right;
        li{
            display: inline;
        }
    }
}