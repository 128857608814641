#ib-related-search {
	display: none;
	border: 1px solid #ddd;
	.ib_title {
		border-bottom: 1px solid #ddd;
		margin-top: 0;
		padding: 10px;
		font-size: 18px;
	}
	.related_search_list {
		padding: 10px;
	}
	.related_block_container {
		margin-bottom: 10px;
		a{
			text-transform: lowercase;
		  	text-decoration: underline;
		}
	}
	.related_block {
		padding: 10px;
	}
}